<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >
      <TransferProductCollapsableItem
        v-for="(product, index) in products"
        :key="product.IdProduct"
        :product="product"
        :selectable="selectable"
        :is-visible="index == 0 ? true : false"
        :show-edit-button="showEditButton"
        :show-delete-button="showDeleteButton"
        :show-pieces-list-actions-buttons="showPiecesListActionsButtons"
      />
    </app-collapse>

    <!-- #region begin::Alert when no brands are available -->
    <b-alert
      v-if="products.length === 0 && !editionModeState"
      variant="warning"
      class="mt-1"
      show
    >
      <div class="alert-body">
        <span>No se agregaron productos.</span>
      </div>
    </b-alert>
  <!-- #endregion end::Alert when no brands are available -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BAlert } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

export default {
  name: 'TransferProductList',
  components: {
    BAlert,
    AppCollapse,
    TransferProductCollapsableItem: () => import('@/modules/production/transfers/components/cards/TransferProductCollapsableItem.vue'),
  },
  props: {
    showEditButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showPiecesListActionsButtons: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    canBeFiltered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      products: [],
    }
  },
  computed: {
    ...mapGetters({
      getTransfer: 'transfers/getTransfer',
      getEditionModeState: 'transfers/getEditionModeState',
      getTransferProducts: 'transfers/getTransferProducts',
      getTransferProductsFiltered: 'transfers/getTransferProductsFiltered',
    }),
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    transferProducts: {
      get() {
        return this.canBeFiltered
          ? this.getTransferProductsFiltered
          : this.getTransferProducts
      },
    },
  },
  watch: {
    transferProducts(products) {
      this.products = [...products]
    },
  },
  async created() {
    this.products = this.transferProducts
  },
}
</script>
