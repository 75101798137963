<template>
  <div>
    <b-form-row>

      <!-- #region::Title -->
      <b-col class="align-self-center">
        <h3>{{ title }}</h3>
      </b-col>
      <!-- #endregion::Title -->

      <!-- #region::Save button -->
      <b-col
        cols="12"
        md="auto"
        class="ml-auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-md-0 w-100 principal-btn"
          @click="callback"
        >
          {{ saveButtonText }}
        </b-button>
      </b-col>
      <!-- #endregion::Save button -->

      <!-- #region::Delete button -->
      <b-col
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="my-lg-0 w-100 delete-btn"
          :to="{ name: modelHomeRoute }"
        >
          {{ cancelButtonText }}
        </b-button>
      </b-col>
      <!-- #endregion::Delete button -->

    </b-form-row>
  </div>
</template>

<script>
import {
  BCol, BButton, BFormRow,
} from 'bootstrap-vue'

export default {
  name: 'MainOptions',
  components: {
    BCol,
    BButton,
    BFormRow,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    saveButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancelar',
    },
    callback: {
      type: Function,
      required: true,
    },
    modelHomeRoute: {
      type: String,
      required: true,
    },
  },
}
</script>
