<template>
  <div>
    <b-skeleton
      class="mt-1"
      animation="wave"
      width="100%"
      height="230px"
    />
    <b-skeleton
      class="mt-1"
      animation="wave"
      width="100%"
      height="100px"
    />
    <b-skeleton
      class="mt-2"
      animation="wave"
      width="100%"
      height="400px"
    />
  </div>
</template>

<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
  components: {
    BSkeleton,
  },
}
</script>
