var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formartedItems.length === 0 && !_vm.editionModeState)?_c('b-alert',{staticClass:"mt-1",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se agregaron circuitos.")])])]):(_vm.formartedItems.length > 0)?_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.formartedItems,"fields":_vm.columns,"responsive":"sm"},scopedSlots:_vm._u([{key:"cell(selectCircuit)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{on:{"change":function($event){return _vm.onCircuitChecked(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"cell(IdCircuit)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.IdCircuit))]),(item.Received === 'No recibida')?_c('div',{staticClass:"sm-banner-alert alert-yellow",attrs:{"role":"alert"}},[_vm._v(" No recibido ")]):_vm._e(),(item.Status === 0)?_c('div',{staticClass:"sm-banner-alert alert-gray",attrs:{"role":"alert"}},[_vm._v(" No disponible ")]):_vm._e(),(item.Transfer && item.Transfer === 'Si')?_c('div',{staticClass:"sm-banner-alert alert-pink",attrs:{"role":"alert"}},[_vm._v(" Traspaso ")]):_vm._e()])]}},{key:"cell(prices)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.PriceMin))+" - "+_vm._s(_vm._f("currency")(item.PriceMax))+" ")]}},{key:"cell(OfferPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currency")(item.OfferPrice)))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.onDeleteCicuit(item)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)]}},(_vm.$ability.can('read', 'Circuit'))?{key:"cell(details)",fn:function(ref){
var detailsShowing = ref.detailsShowing;
var item = ref.item;
return [_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.toggleDetails(item)}}},[_c('feather-icon',{attrs:{"icon":detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'}})],1)]}}:null,(_vm.$ability.can('read', 'Circuit'))?{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-card',{staticClass:"mt-1",attrs:{"no-body":""}},[_vm._l((item.products),function(product,index){return _c('TransferProductCollapsableItem',{key:product.IdProduct,attrs:{"product":product,"show-edit-button":false,"show-delete-button":false,"is-visible":index == 0 ? true : false,"show-pieces-list-actions-buttons":false}})}),(!item.products || item.products.length === 0)?_c('b-row',[_c('b-col',[_c('h3',[_vm._v("Circuito "+_vm._s(item.IdCircuit))])])],1):_vm._e()],2)]}}:null],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }