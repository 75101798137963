<template>
  <div>
    <!-- #region begin:title -->
    <b-form-row v-if="showTitle">
      <b-col
        cols="12"
        class="mb-1"
      >
        <h3>Circuitos</h3>
      </b-col>
    </b-form-row>
    <!-- #endregion end:title -->

    <!-- #region begin:: List options -->
    <b-form-row>

      <!-- #region begin::Searcher -->
      <b-col md="4">
        <BasicSearcher
          ref="basic-searcher"
          :callback="handleSearch"
          placeholder="Buscar circuitos"
        />
      </b-col>
      <!-- #endregion end::Searcher -->

      <!-- #region begin::New cicuit button -->
      <b-col
        v-if="showCreateButton && $ability.can('create', 'Circuit')"
        cols="12"
        md="auto"
        class="ml-auto"
      ><b-button
        variant="principal-btn"
        class="my-1 my-lg-0 w-100 principal-btn"
        @click="$router.push({ name: 'new-circuit', params: { number: circuitNumber } })"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Nuevo circuito</span>
      </b-button></b-col>
      <!-- #endregion end::New cicuit button -->

      <!-- #region begin::Download CSV button -->
      <b-col
        v-if="showDownloadFile && $ability.can('download', 'Circuits')"
        cols="12"
        md="auto"
      ><b-button
        variant="flat-success"
        class="w-100 csv-btn"
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Descargar CSV</span>
      </b-button></b-col>
      <!-- #endregion end::Download CSV button -->

    </b-form-row>
    <!-- #endregion end:: List options -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BButton, BCol, BFormRow,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'

export default {
  components: {
    BCol,
    BButton,
    BFormRow,
    BasicSearcher,
  },
  props: {
    /**
     * Número de circuito
     */
    circuitNumber: {
      type: [Number, String],
      required: true,
    },
    /**
     * Determina si se muestran los botones de opciones para
     * crear un nuevo producto
     */
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Determina si se muestra el botón para descargar el archivo CSV
     */
    showDownloadFile: {
      type: Boolean,
      default: true,
    },
    /**
     * Determina si se muestra el título
     */
    showTitle: {
      type: Boolean,
      default: true,
    },
    /**
     * Determina si se cargan los filtros globales del estado
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
    }),
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
    },
  },
  mounted() {
    // If we can apply global filters, load them from the store into the filter component.
    if (this.canApplyGlobalFilters) {
      // Carga el término de búsqueda del store de productos
      if (this.filteredSearchTerm) {
        this.$refs['basic-searcher'].loadSearchTerm(this.filteredSearchTerm)
      }
    }
  },
  methods: {
    /**
    * Evento del buscador básico
    * @summary Emite un evento con el término de búsqueda
    * @param {String} searchTerm - Término de búsqueda
    */
    handleSearch(searchTerm) {
      this.$emit('on-filter', { type: 'search', value: searchTerm })
    },
  },
}
</script>
