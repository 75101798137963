<template>

  <!-- #region::Add circuits to quote modal -->
  <b-modal
    id="circuits-modal"
    ref="circuits-modal"
    title="Agregar circuitos a la orden de traspaso"
    centered
    size="xl"
    @hidden="onCloseModal"
  >
    <b-card-text>
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <h3>Circuitos</h3>
        </b-col>

        <b-col
          cols="12"
        >
          <b-alert
            show
            variant="info"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertCircleIcon"
              />
              <span class="ml-25">Las órdenes de traspaso deben tener productos y piezas de la misma ubicación.</span>
            </div>
          </b-alert>
        </b-col>

        <!-- #region begin::Products list -->
        <b-col>
          <TransferAddingCircuitList
            :can-apply-global-filters="false"
            :can-apply-global-pagination="false"
          />
        </b-col>
        <!-- #endregion end::Products list -->
      </b-row>
    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        @click="onAddCircuits"
      >
        {{ addButtonText }}
      </b-button>
    </template>
    <!-- #endregion::Footer -->
  </b-modal>
  <!-- #endregion::Add circuits to quote modal -->

</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BModal, VBModal, BCardText, BButton, BAlert,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TransferAddingCircuitList from '@/modules/production/transfers/components/lists/TransferAddingCircuitList.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BModal,
    BButton,
    BCardText,
    TransferAddingCircuitList,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      getTransferPieces: 'transfers/getTransferPieces',
      getTransferCircuits: 'transfers/getTransferCircuits',
      getTransferSelectedCircuits: 'transfers/getTransferSelectedCircuits',
    }),
    transferCircuits: {
      get() { return this.getTransferCircuits },
    },
    selectedCircuits: {
      get() { return this.getTransferSelectedCircuits },
    },
    transferPieces: {
      get() { return this.getTransferPieces },
    },
    addButtonText() {
      let text = 'Añadir a orden de traspaso'
      let totalCircuitsCount = 0

      if (this.transferCircuits.length >= this.selectedCircuits.length) {
        totalCircuitsCount = this.transferCircuits.length
      } else {
        totalCircuitsCount = this.selectedCircuits.length
      }

      if (totalCircuitsCount === 0) {
        return text
      }

      text += ` (${totalCircuitsCount})`

      return text
    },
  },
  methods: {
    ...mapActions({
      addTransferCircuit: 'transfers/addTransferCircuit',
      cleanSelectedCircuits: 'transfers/cleanSelectedCircuits',
    }),
    showModal() {
      this.$refs['circuits-modal'].show()
    },
    hideModal() {
      this.$refs['circuits-modal'].hide()
    },
    onAddCircuits() {
      this.selectedCircuits.forEach(selectedCircuit => {
        const existsAtIndex = this.transferCircuits.findIndex(c => c.IdCircuit === selectedCircuit.IdCircuit)

        if (existsAtIndex !== -1) {
          return
        }

        const transferCircuit = { ...selectedCircuit }

        const transferCircuitsProducts = []

        const uuidCircuit = uuidv4()

        selectedCircuit.products.forEach(product => {
          const pieces = []

          if (!product.QtyIventory) {
            this.$set(product, 'QtyIventory', product.Qty)
          }

          product.detailCircuit.forEach(detailCircuit => {
            const { piece } = detailCircuit
            this.$set(piece, 'Status', detailCircuit.Status)
            pieces.push(piece)
          })

          this.$set(product, 'pieces', pieces)
          transferCircuitsProducts.push({ ...product })
        })

        transferCircuit.products = transferCircuitsProducts
        this.$set(transferCircuit, 'uuid', uuidCircuit)
        this.addTransferCircuit(transferCircuit)
      })
      this.hideModal()
    },
    onCloseModal() {
      this.cleanSelectedCircuits()
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
