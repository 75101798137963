<template>
  <div>

    <!-- #region::Transfer products list -->
    <b-form-row>

      <!-- #region::Tittle -->
      <b-col md="4">
        <h3>Productos</h3>
      </b-col>
      <!-- #endregion::Tittle -->

      <!-- #region begin::Add product to quote button -->
      <b-col
        v-if="showAddProductButton"
        cols="12"
        md="auto"
        class="ml-auto"
      ><b-button
        variant="principal-btn"
        class="my-1 my-lg-0 w-100 principal-btn"
        @click="$refs.TransferProductsModal.showModal()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Agregar producto</span>
      </b-button></b-col>
      <!-- #endregion end::Add product to quote button -->

    </b-form-row>
    <!-- #endregion::Transfer products list -->

    <!-- #region::Empty list content -->
    <b-card
      v-if="canShowEmptyProductsListMessageBox"
      class="mt-1 border border-dark shadow-none text-center"
    >
      <b-card-body class="py-0">
        <p>
          <feather-icon
            icon="BoxIcon"
            class="feather-48"
          />
        </p>
        <span>Aquí se mostrarán los productos</span>
      </b-card-body>
    </b-card>
    <!-- #endregion::Empty list content -->

    <TransferProductsList
      v-else
      :selectable="selectable"
      :can-be-filtered="canBeFiltered"
      :show-edit-button="showEditButton"
      :show-delete-button="showDeleteButton"
      :show-pieces-list-actions-buttons="showPiecesListActionsButtons"
    />

    <TransferProductsModal
      ref="TransferProductsModal"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BButton, BCol, BFormRow, BCard, BCardBody,
} from 'bootstrap-vue'

import TransferProductsModal from '@/modules/production/transfers/components/modals/TransferProductsModal.vue'
import TransferProductsList from '@/modules/production/transfers/components/lists/TransferProductsList.vue'

import buildProduct from '@/helpers/ProductConverter'

export default {
  name: 'TransferProducts',
  components: {
    BCol,
    BCard,
    BButton,
    BFormRow,
    BCardBody,
    TransferProductsModal,
    TransferProductsList,
  },
  props: {
    productsData: {
      type: Array,
      default: () => [],
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showAddProductButton: {
      type: Boolean,
      default: true,
    },
    showPiecesListActionsButtons: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    canBeFiltered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getTransfer: 'transfers/getTransfer',
      getTransferProducts: 'transfers/getTransferProducts',
      getEditionModeState: 'transfers/getEditionModeState',
    }),
    transferProducts: {
      get() { return this.getTransferProducts },
    },
    transfer: {
      get() {
        return this.getTransfer ? this.getTransfer.transfer : null
      },
    },
    products: {
      get() {
        return this.getTransfer.products
      },
    },
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    canShowEmptyProductsListMessageBox() {
      return this.transferProducts.length === 0
        && (this.$route.name === 'new-transfer' || this.editionModeState)
    },
  },
  watch: {
    transfer() {
      this.buildProductsList()
    },
  },
  created() {
    this.buildProductsList()
  },
  methods: {
    ...mapActions({
      addTransferProduct: 'transfers/addTransferProduct',
    }),
    buildProductsList() {
      if (this.transfer) {
        this.products.forEach(product => {
          this.addTransferProduct(buildProduct(product))
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 48px;
    height: 48px;
  }
</style>
