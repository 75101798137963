<template>

  <!-- #region::Add products to quote modal -->
  <b-modal
    id="products-modal"
    ref="products-modal"
    title="Agregar productos a la orden de traspaso"
    centered
    size="xl"
    @hidden="onCloseModal"
  >

    <b-card-text>
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <h3>Productos</h3>
        </b-col>

        <b-col
          cols="12"
        >
          <b-alert
            show
            variant="info"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertCircleIcon"
              />
              <span class="ml-25">La orden de traspaso deben tener productos y piezas de la misma ubicación.</span>
            </div>
          </b-alert>
        </b-col>

        <!-- #region begin::Products list -->
        <b-col>
          <ProductsList
            resource="products"
            :check-transfer="true"
            :show-pieces-list="true"
            :show-location-filter="true"
            :selectable-pieces-list="true"
            :can-apply-global-filters="false"
            :can-apply-global-pagination="false"
            @hide-modal="hideModal"
          />
        </b-col>
        <!-- #endregion end::Products list -->
      </b-row>
    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        @click="onAddPieces"
      >
        {{ addButtonText }}
      </b-button>
    </template>
    <!-- #endregion::Footer -->

  </b-modal>
  <!-- #endregion::Add products to quote modal -->

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BModal, VBModal, BCardText, BButton, BAlert,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BModal,
    BButton,
    BCardText,
    ProductsList: () => import('@/modules/production/products/components/ProductsList.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      getTransfer: 'transfers/getTransfer',
      getTransferPieces: 'transfers/getTransferPieces',
      getTransferProducts: 'transfers/getTransferProducts',
      getTransferCircuits: 'transfers/getTransferCircuits',
      getProvitionalPieces: 'circuits/getProvitionalPieces',
      getProvitionalProducts: 'circuits/getProvitionalProducts',
    }),
    /**
     * Información de la orden de traspaso
     */
    transfer: {
      get() { return this.getTransfer.transfer },
    },
    /**
     * Piezas de la orden de traspaso
     */
    transferPieces: {
      get() { return this.getTransferPieces },
    },
    /**
     * Productos de la orden de traspaso
     */
    transferProducts: {
      get() { return this.getTransferProducts },
    },
    /**
     * Circuitos de la orden de traspaso
     */
    transferCircuits: {
      get() { return this.getTransferCircuits },
    },
    /**
     * Piezas seleccionadas para agregar a la orden de traspaso
     */
    provitionalPieces: {
      get() { return this.getProvitionalPieces },
    },
    /**
     * Productos seleccionados para agregar a la orden de traspaso
     */
    provitionalProducts: {
      get() { return this.getProvitionalProducts },
    },
    /**
     * Texto del botón de agregar a la orden de traspaso
     */
    addButtonText() {
      let text = 'Añadir a la orden de traspaso'

      if (this.provitionalPieces.length > 0) {
        text += ` (${this.provitionalPieces.length})`
      }

      return text
    },
  },
  methods: {
    ...mapActions({
      addTransferProduct: 'transfers/addTransferProduct',
      clearFormData: 'circuits/clearFormData',
    }),
    showModal() {
      this.$refs['products-modal'].show()
    },
    hideModal() {
      this.$refs['products-modal'].hide()
    },
    onAddPieces() {
      if (this.provitionalPieces.length > 0) {
        const addedPiecesIds = this.transferPieces.map(piece => piece.IdPiece)

        const isInTransfer = piece => piece.Transfer === 'Si' && !addedPiecesIds.includes(piece.IdPiece)
        const inTransfer = this.provitionalPieces.some(isInTransfer)

        if (inTransfer) {
          this.showToast(
            'Error de validación',
            'Una o más piezas seleccionadas se encuentran en una orden de traspaso.',
            'warning',
          )
          return
        }

        const isSameLocation = piece => piece.Location === this.provitionalPieces[0].Location
        const sameLocation = this.provitionalPieces.every(isSameLocation)

        if (!sameLocation) {
          this.showToast(
            'Error de validación',
            'Las piezas seleccionadas no pertenecen a la misma ubicación.',
            'warning',
          )
          return
        }

        if (this.transferPieces.length > 0) {
          const isSameLocationTransfer = piece => piece.Location === this.transferPieces[0].Location
          const sameLocationTransfer = this.provitionalPieces.every(isSameLocationTransfer)

          if (!sameLocationTransfer) {
            this.showToast(
              'Error de validación',
              'Existen piezas con diferente ubicación agregadas en la orden de traspaso.',
              'warning',
            )
            return
          }
        }

        if (this.transferCircuits.length > 0) {
          const isSameLocationCircuit = piece => piece.Location === this.transferCircuits[0].Location
          const sameLocationCircuit = this.provitionalPieces.every(isSameLocationCircuit)

          if (!sameLocationCircuit) {
            this.showToast(
              'Error de validación',
              'Existen circuitos con diferente ubicación agregados en la orden de traspaso.',
              'warning',
            )
            return
          }
        }

        if (this.$route.name === 'transfer-details') {
          const transferStatus = this.transfer.StatusText

          if (transferStatus === 'Aprobada' || transferStatus === 'Rechazada') {
            const isSameLocationTransfer = piece => piece.Location === this.transfer.LocationDestiny
            const sameLocationTransfer = this.provitionalPieces.some(isSameLocationTransfer)

            if (sameLocationTransfer) {
              this.showToast(
                'Error de validación',
                'La ubicación de una o más piezas coincide con la dirección de destino de la orden de traspaso.',
                'warning',
              )
              return
            }
          }
        }

        this.provitionalProducts.forEach(provitionalProduct => {
          const transferProduct = { ...provitionalProduct }
          transferProduct.pieces = provitionalProduct.pieces.map(piece => {
            const pieceInTransfer = this.transferPieces.find(p => p.IdPiece === piece.IdPiece)
            if (pieceInTransfer) {
              this.$set(piece, 'Transfer', pieceInTransfer.Transfer)
            }
            return { ...piece }
          })

          // * 16/03/2023 - TAG: Set this when product add to transfer edition form directry
          // * from products list modal (same format info, not build converter). QtyIventory neccessary
          // * for show real count from invetory
          if (this.$route.name === 'transfer-details') {
            this.$set(transferProduct, 'QtyIventory', transferProduct.Qty)
          }

          this.addTransferProduct(transferProduct)
          this.hideModal()
        })
      } else {
        this.showToast(
          'Error de validación',
          'No hay piezas seleccionadas para agregar a la cotización.',
          'warning',
        )
      }
    },
    onCloseModal() {
      this.clearFormData()
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
