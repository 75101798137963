<template>
  <div>

    <!-- #region begin:: List options -->
    <b-form-row>

      <!-- #region begin::Searcher -->
      <b-col md="4">
        <h3>Circuitos</h3>
      </b-col>
      <!-- #endregion end::Searcher -->

      <!-- #region begin::Add circuit to quote button -->
      <b-col
        v-if="showAddCircuitButton"
        cols="12"
        md="auto"
        class="ml-auto"
      ><b-button
        variant="principal-btn"
        class="my-1 my-lg-0 w-100 principal-btn"
        @click="$refs.circuitsModal.showModal()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Agregar circuito</span>
      </b-button></b-col>
      <!-- #endregion end::Add circuit to quote button -->

    </b-form-row>
    <!-- #endregion end:: List options -->

    <!-- #region::Empty list content -->
    <b-card
      v-if="canShowEmptyCircuitsListMessageBox"
      class="mt-1 border border-dark shadow-none text-center"
    >
      <b-card-body class="py-0">
        <p>
          <feather-icon
            icon="LayersIcon"
            class="feather-48"
          />
        </p>
        <span>Aquí se mostrarán los circuitos</span>
      </b-card-body>
    </b-card>
    <!-- #endregion::Empty list content -->

    <TransferCircuitsList
      v-else
      :selectable="selectable"
      :can-be-filtered="canBeFiltered"
      :show-delete-button="showDeleteButton"
    />

    <TransferCircuitsModal
      ref="circuitsModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BButton, BCol, BFormRow, BCard, BCardBody,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import buildProduct from '@/helpers/CircuitProductConverter'
import TransferCircuitsList from '@/modules/production/transfers/components/lists/TransferCircuitsList.vue'
import TransferCircuitsModal from '@/modules/production/transfers/components/modals/TransferCircuitsModal.vue'

export default {
  components: {
    BCol,
    BCard,
    BButton,
    BFormRow,
    BCardBody,
    TransferCircuitsList,
    TransferCircuitsModal,
  },
  props: {
    circuitsData: {
      type: Array,
      default: () => [],
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showAddCircuitButton: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    canBeFiltered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getTransfer: 'transfers/getTransfer',
      getTransferCircuits: 'transfers/getTransferCircuits',
      getEditionModeState: 'transfers/getEditionModeState',
    }),
    transfer: {
      get() {
        return this.getTransfer ? this.getTransfer.transfer : null
      },
    },
    circuits: {
      get() {
        return this.getTransfer ? this.getTransfer.circuits : null
      },
    },
    transferCircuits: {
      get() { return this.getTransferCircuits },
    },
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    canShowEmptyCircuitsListMessageBox() {
      return this.transferCircuits.length === 0
        && (this.$route.name === 'new-transfer' || this.editionModeState)
    },
  },
  watch: {
    transfer() {
      this.buildCircuitsList()
    },
  },
  created() {
    this.buildCircuitsList()
  },
  methods: {
    ...mapActions({
      addTransferCircuit: 'transfers/addTransferCircuit',
    }),
    buildCircuitsList() {
      if (this.circuitsData.length > 0) {
        this.circuitsData.forEach(circuit => {
          this.buildCircuitAndAddToState(circuit)
        })
      } else if (this.circuits && this.circuits.length > 0) {
        this.circuits.forEach(circuit => {
          this.buildCircuitAndAddToState(circuit)
        })
      }
    },
    buildCircuitAndAddToState(circuit) {
      const transferCircuit = { ...circuit }

      const transferCircuitsProducts = []

      const uuidCircuit = uuidv4()

      circuit.products.forEach(product => {
        const formProduct = buildProduct(product)
        transferCircuitsProducts.push({ ...formProduct })
      })

      transferCircuit.products = transferCircuitsProducts
      this.$set(transferCircuit, 'uuid', uuidCircuit)

      const existsAtIndex = this.transferCircuits.findIndex(c => c.IdCircuit === transferCircuit.IdCircuit)

      if (existsAtIndex === -1) {
        this.addTransferCircuit(transferCircuit)
      } else {
        const transferCircuitAdded = this.transferCircuits.find(c => c.IdCircuit === transferCircuit.IdCircuit)

        if (transferCircuit.Received) {
          this.$set(transferCircuitAdded, 'Received', transferCircuit.Received)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 48px;
    height: 48px;
  }
</style>
