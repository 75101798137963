<template>
  <div>
    <!-- #region begin::Alert when no brands are available -->
    <b-alert
      v-if="formartedItems.length === 0 && !editionModeState"
      variant="warning"
      class="mt-1"
      show
    >
      <div class="alert-body">
        <span>No se agregaron circuitos.</span>
      </div>
    </b-alert>
    <!-- #endregion end::Alert when no brands are available -->

    <b-card
      v-else-if="formartedItems.length > 0"
      class="mt-1 border border-dark shadow-none"
      no-body
    >
      <b-card-text>

        <!-- #region::Circuits list -->
        <b-table
          :items="formartedItems"
          :fields="columns"
          responsive="sm"
          class="my-0"
        >
          <!-- #region::A virtual column for checbox -->
          <template #cell(selectCircuit)="{item}">
            <b-form-checkbox
              v-model="item.selected"
              @change="onCircuitChecked(item)"
            />
          </template>
          <!-- #endregion::A virtual column for checbox -->

          <!-- #region::A virtual column for circuit id -->
          <template #cell(IdCircuit)="{ item }">
            <div class="d-flex">
              <span class="text-nowrap">{{ item.IdCircuit }}</span>
              <div
                v-if="item.Received === 'No recibida'"
                class="sm-banner-alert alert-yellow"
                role="alert"
              >
                No recibido
              </div>
              <div
                v-if="item.Status === 0"
                class="sm-banner-alert alert-gray"
                role="alert"
              >
                No disponible
              </div>
              <div
                v-if="item.Transfer && item.Transfer === 'Si'"
                class="sm-banner-alert alert-pink"
                role="alert"
              >
                Traspaso
              </div>
            </div>
          </template>
          <!-- #endregion::A virtual column for circuit id -->

          <!-- #region::A virtual column for prices -->
          <template #cell(prices)="{ item }">
            {{ item.PriceMin | currency }} - {{ item.PriceMax | currency }}
          </template>
          <!-- #endregion::A virtual column for prices -->

          <!-- #region::A virtual column for offer price -->
          <template #cell(OfferPrice)="{ item }">
            <span class="text-nowrap">{{ item.OfferPrice | currency }}</span>
          </template>
          <!-- #endregion::A virtual column for offer price -->

          <!-- #region::A virtual column for actions -->
          <template #cell(actions)="{ item }">

            <!-- #region::Delete circuit button -->
            <b-button
              v-b-tooltip.hover.top="'Eliminar'"
              variant="flat-secondary"
              class="btn-icon rounded-circle"
              @click="onDeleteCicuit(item)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          <!-- #endregion::Delete circuit button -->

          </template>
          <!-- #endregion::A virtual column for actions -->

          <!-- #region::A virtual column for toggle button -->
          <template
            v-if="$ability.can('read', 'Circuit')"
            #cell(details)="{ detailsShowing, item }"
          >
            <b-button
              variant="flat-secondary"
              class="btn-icon rounded-circle"
              @click="toggleDetails(item)"
            >
              <feather-icon :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
            </b-button>
          </template>
          <!-- #endregion::A virtual column for toggle button -->

          <!-- #region::Circuit details -->
          <template
            v-if="$ability.can('read', 'Circuit')"
            v-slot:row-details="{ item }"
          >
            <b-card
              no-body
              class="mt-1"
            >
              <TransferProductCollapsableItem
                v-for="(product, index) in item.products"
                :key="product.IdProduct"
                :product="product"
                :show-edit-button="false"
                :show-delete-button="false"
                :is-visible="index == 0 ? true : false"
                :show-pieces-list-actions-buttons="false"
              />

              <b-row v-if="!item.products || item.products.length === 0">
                <b-col>
                  <h3>Circuito {{ item.IdCircuit }}</h3>
                </b-col>
              </b-row>
            </b-card>
          </template>
        <!-- #endregion::Circuit details -->

        </b-table>
      <!-- #endregion::Circuits list -->

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  VBTooltip, BTable, BButton, BCard, BCardText, BAlert, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  name: 'CircuitsList',
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BTable,
    BButton,
    BCardText,
    BFormCheckbox,
    TransferProductCollapsableItem: () => import('@/modules/production/transfers/components/cards/TransferProductCollapsableItem.vue'),

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    // * Filter props
    showDownloadFile: {
      type: Boolean,
      default: false,
    },
    // * Table props
    showToggleButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    canBeFiltered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'selectCircuit',
          label: '',
          thClass: this.selectable ? 'bg-light' : 'd-none',
          tdClass: this.selectable ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'IdCircuit',
          label: 'No. Circuito',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'prices',
          label: 'Precio',
          thClass: this.$ability.can('create', 'Circuit') ? 'bg-light' : 'd-none',
          thStyle: this.$ability.can('create', 'Circuit') ? 'text-transform: none;' : '',
          tdClass: this.$ability.can('create', 'Circuit') ? '' : 'd-none',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Qty',
          label: 'No. de productos',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: this.showDeleteButton ? 'bg-light' : 'd-none',
          tdClass: this.showDeleteButton ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'details',
          label: '',
          thClass: this.$ability.can('read', 'Circuit') ? 'bg-light' : 'd-none',
          tdClass: this.showToggleButton && this.$ability.can('read', 'Circuit') ? '' : 'd-none',
        },
      ],
      selected: [],
      allOpenRows: [],
      currentItems: [],
      isLoadingCircuits: false,
    }
  },
  computed: {
    ...mapGetters({
      getEditionModeState: 'transfers/getEditionModeState',
      getTransferCircuits: 'transfers/getTransferCircuits',
      getTransferCircuitsFiltered: 'transfers/getTransferCircuitsFiltered',
    }),
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    circuits: {
      get() {
        return this.canBeFiltered
          ? this.getTransferCircuitsFiltered
          : this.getTransferCircuits
      },
    },
    formartedItems() {
      if (!this.circuits) return []
      return this.circuits.map(item => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        this.offRow(item)
        return item
      })
    },
  },
  watch: {
    editionModeState(state) {
      if (state) {
        this.$set(this.columns[7], 'thClass', 'bg-light')
        this.$set(this.columns[7], 'tdClass', '')
      } else {
        this.$set(this.columns[7], 'thClass', 'd-none')
        this.$set(this.columns[7], 'tdClass', 'd-none')
      }
    },
  },
  methods: {
    ...mapActions({
      updateTransferCircuit: 'transfers/updateTransferCircuit',
      deleteTransferCircuit: 'transfers/deleteTransferCircuit',
      addReceivedProvitionalCircuit: 'transfers/addReceivedProvitionalCircuit',
      deleteReceivedProvitionalCircuit: 'transfers/deleteReceivedProvitionalCircuit',
    }),
    onRowSelected(items) {
      this.selected = items
    },
    toggleDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.offRow(row)
      } else {
        for (let index = 0; index < this.currentItems.length; index += 1) {
          this.offRow(this.$set(this.currentItems[index]))
        }

        this.currentItems.forEach(item => {
          this.offRow(item)
        })

        this.$nextTick(() => {
          this.onRow(row)
        })
      }
    },
    offRow(row) {
      this.$set(row, '_showDetails', false)
      this.$set(row, '_cellVariants', {
        total: '', IdCircuit: '', Name: '', prices: '', OfferPrice: '', Qty: '', Location: '', actions: '', details: '',
      })
    },
    onRow(row) {
      this.$set(row, '_showDetails', true)
      this.$set(row, '_cellVariants', {
        total: 'primary', IdCircuit: 'primary', Name: 'primary', prices: 'primary', OfferPrice: 'primary', Qty: 'primary', Location: 'primary', actions: 'primary', details: 'primary',
      })
    },
    onCircuitChecked(circuit) {
      const selectedCircuit = { ...circuit }
      selectedCircuit.selected = !selectedCircuit.selected
      if (!selectedCircuit.selected) {
        this.addReceivedProvitionalCircuit(selectedCircuit)
      } else {
        this.deleteReceivedProvitionalCircuit(selectedCircuit)
      }
    },
    onDeleteCicuit(circuit) {
      const transferCircuits = this.circuits.filter(c => c.IdCircuit === circuit.IdCircuit)
      const circuitsCount = transferCircuits.length

      transferCircuits.forEach(transferCircuit => {
        const updatedCircuit = transferCircuit
        updatedCircuit.count = circuitsCount - 1
        this.updateTransferCircuit(updatedCircuit)
      })

      this.deleteTransferCircuit(circuit)
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
