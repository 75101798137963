var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CircuitListFilters',{attrs:{"show-title":false,"show-create-button":false,"circuit-number":_vm.totalCircuits + 1,"show-download-file":_vm.showDownloadFile,"can-apply-global-filters":_vm.canApplyGlobalFilters},on:{"on-filter":_vm.handleFilter}}),(_vm.isLoadingCircuits)?[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"wave","width":"100%","height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableCircuits)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.formartedItems,"fields":_vm.columns,"responsive":"sm"},scopedSlots:_vm._u([{key:"cell(selectCircuit)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{on:{"change":function($event){return _vm.onCircuitChecked(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"cell(IdCircuit)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.IdCircuit))]),(item.Transfer && item.Transfer === 'Si')?_c('div',{staticClass:"sm-banner-alert alert-pink",attrs:{"role":"alert"}},[_vm._v(" Traspaso ")]):_vm._e()])]}},{key:"cell(prices)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.PriceMin))+" - "+_vm._s(_vm._f("currency")(item.PriceMax))+" ")]}},{key:"cell(OfferPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currency")(item.OfferPrice)))])]}},(_vm.$ability.can('read', 'Circuit'))?{key:"cell(details)",fn:function(ref){
var detailsShowing = ref.detailsShowing;
var item = ref.item;
return [_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.toggleDetails(item)}}},[_c('feather-icon',{attrs:{"icon":detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'}})],1)]}}:null,(_vm.$ability.can('read', 'Circuit'))?{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-card',{staticClass:"mt-1",attrs:{"no-body":""}},[(item.products)?_c('CircuitsListDetails',{attrs:{"id":item.IdCircuit,"products":item.products,"show-pieces-list":true}}):_vm._e()],1)]}}:null],null,true)}):_vm._e(),(!_vm.availableCircuits && !_vm.isLoadingCircuits)?_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron circuitos.")])])]):_vm._e()],1)],1)],(_vm.availableCircuits)?_c('BasicPaginator',{staticClass:"mt-2",attrs:{"total-rows":_vm.totalCircuits,"callback":_vm.handleChangePagination,"can-apply-global-pagination":_vm.canApplyGlobalPagination}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }